import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useSearchParams } from "react-router-dom";

import { Field } from "../services/collectionFields";

interface QueryParamsState {
  filters: Record<string, string[]>;
  sort: Record<string, string | undefined>;
  search: { keywords?: string };
  pagination: { page?: number; limit?: number };
}

interface UpdateQueryParams {
  setSearch: (keywords: string) => void;
  setFilters: (filters: Record<string, string[]>) => void;
  setPagination: (page?: number, limit?: number) => void;
  setSort: (sort: Record<string, string | undefined>) => void;
}

export const useQueryParamsToState = (): QueryParamsState &
  UpdateQueryParams & { toQueryParamsUrl: () => string } => {
  const [searchParams] = useSearchParams();

  const setSearch = (keywords: string) => {
    searchParams.delete("search");

    if (keywords) searchParams.append("search", keywords);
  };

  const setFilters = (filters: Record<string, string[]>) => {
    searchParams.delete("filter");

    if (!isEmpty(filters)) {
      Object.keys(filters).forEach((field) => {
        searchParams.append(`filter`, `${field}:::${filters[field].join(",")}`);
      });
    }
  };

  const setPagination = (page?: number, limit?: number) => {
    searchParams.delete("page");
    searchParams.delete("limit");

    searchParams.append("page", page?.toString() || "1");
    searchParams.append("limit", limit?.toString() || "50");
  };

  const setSort = (sort: Record<string, string | undefined>) => {
    searchParams.delete("sort");

    if (!isEmpty(sort)) {
      Object.keys(sort).forEach((field) => {
        if (!sort[field]) searchParams.delete("sort");
        else
          searchParams.append(
            "sort",
            sort[field] === "ascend" ? field : `-${field}`
          );
      });
    }
  };

  const toQueryParamsUrl = (): string => searchParams.toString();

  let filters = {},
    sort = {},
    pagination = {},
    search = {};
  searchParams.forEach((value, key) => {
    // filters
    if (key === "filter") {
      const fieldName = value.split(":::")[0];
      filters = { ...filters, [fieldName]: value.split(":::")[1].split(",") };
    }
    // sort
    if (key === "sort") {
      if (value.startsWith("-"))
        sort = { ...sort, [value.slice(1)]: "descend" };
      else sort = { ...sort, [value]: "ascend" };
    }

    // pagination and search
    if (["page", "limit", "search"].includes(key)) {
      pagination = { ...pagination, [key]: value };
    }

    // search
    if (key === "search") search = { ...search, keywords: value };
  });

  return {
    filters,
    sort,
    pagination,
    search,
    toQueryParamsUrl,
    setFilters,
    setPagination,
    setSearch,
    setSort,
  };
};

const directusOperators: Record<string, string> = {
  "<": "_lt",
  "<=": "_lte",
  ">": "_gt",
  ">=": "_gte",
  "=": "_eq",
  "!=": "_neq",
};

const yearFilterIntervals: Record<number, string> = {
  1919: "<1919",
  1945: "1919-1945",
  1960: "1946-1960",
  1970: "1961-1970",
  1980: "1971-1980",
  1985: "1981-1985",
  1990: "1986-1990",
  1995: "1991-1995",
  2000: "1996-2000",
  2005: "2001-2005",
  2010: "2006-2010",
  2015: "2011-2015",
};

export const transformToApiQueryParams = (
  fields: Field[],
  urlParams: URLSearchParams,
  exclude?: string[]
) => {
  const apiParams = new URLSearchParams();
  let filterParamValue = {};
  urlParams.forEach((value, key) => {
    if (!(exclude && exclude.includes(key))) {
      if (key === "filter") {
        const [field, fieldValue] = value.split(":::");
        const foundField = fields.find((f) => f.name === field);
        if (foundField && foundField?.name === "Usages") {
          let usagesFilter: any[] = [];
          const filterValues = fieldValue.split(",");
          if (filterValues.includes("_empty")) {
            usagesFilter = [...usagesFilter, { _null: true }];
          }

          const withoutEmptyValues = filterValues.filter((v) => v !== "_empty");
          withoutEmptyValues.forEach(
            (v) => (usagesFilter = [...usagesFilter, { _contains: v }])
          );
          filterParamValue = {
            ...filterParamValue,
            Usages: { _or: usagesFilter },
          };
        } else if (foundField && foundField.ui === "datetime") {
          if (fieldValue.includes("***")) {
            filterParamValue = {
              ...filterParamValue,
              [field]: {
                _eq: new Intl.DateTimeFormat("fr-CA", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                }).format(
                  dayjs(
                    `${fieldValue.replaceAll("*", "")}`,
                    "DD/MM/YYYY"
                  ).toDate()
                ),
              },
            };
          } else {
            try {
              filterParamValue = {
                ...filterParamValue,
                [field]: {
                  _between: fieldValue.split(",").map((v) =>
                    new Intl.DateTimeFormat("fr-CA", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    }).format(dayjs(v.trim() as string, "DD/MM/YYYY").toDate())
                  ),
                },
              };
            } catch (error) {
              console.error(error);
            }
          }
        } else if (
          foundField?.type !== "float" &&
          foundField?.type !== "integer" &&
          foundField?.type !== "date" &&
          fieldValue.split(",").length === 1 &&
          fieldValue.endsWith("***")
        ) {
          if (foundField?.name === "AnneeConstruction") {
            const year = +fieldValue.slice(0, fieldValue.length - 3);

            let filter = year.toString();
            const keys = Object.keys(yearFilterIntervals);
            for (let i = 0; i < keys.length; i++) {
              const y = +keys[i];
              if (year <= +y) {
                filter = yearFilterIntervals[+y];
                break;
              }
            }
            filterParamValue = {
              ...filterParamValue,
              [field]: {
                _in: [filter, year.toString()],
              },
            };
          } else {
            filterParamValue = {
              ...filterParamValue,
              [field]: {
                _contains: fieldValue.slice(0, fieldValue.length - 3),
              },
            };
          }
        } else if (
          foundField?.type === "integer" ||
          foundField?.type === "float"
        ) {
          if (["<", ">", "="].includes(fieldValue.trim()[0])) {
            const operator = fieldValue.trim()[0];
            const value = fieldValue
              .split("")
              .slice(1)
              .join("")
              .replaceAll("*", "");
            if (value) {
              filterParamValue = {
                ...filterParamValue,
                [field]: {
                  [directusOperators[operator]]: value,
                },
              };
            }
          } else {
            filterParamValue = {
              ...filterParamValue,
              [field]: {
                _between: fieldValue,
              },
            };
          }
        } else {
          filterParamValue = {
            ...filterParamValue,
            [field]: {
              _in: fieldValue.split(",").map((v) => v.trim()),
            },
          };
        }
      } else {
        apiParams.append(key, value);
      }
    }
  });
  // outside to not repeat the "filter" parameter
  apiParams.append("filter", JSON.stringify(filterParamValue));

  if (!urlParams.has("page")) apiParams.append("page", "1");

  if (!urlParams.has("limit")) apiParams.append("limit", "50");

  return apiParams;
};
