import { FC, Suspense, useEffect, useState } from "react";

import { LoadingOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { Button, Checkbox, Spin, Table, Tag, Typography } from "antd";
import {
  FilterDropdownProps,
  FilterValue,
  SortOrder,
  SorterResult,
  TablePaginationConfig,
} from "antd/es/table/interface";
import { isArray } from "lodash";
import {
  Await,
  useLoaderData,
  useNavigate,
  useRevalidator,
} from "react-router-dom";
import { Link } from "react-router-dom";

import CollectionTableHeader from "./CollectionTableHeader";
import EditCollection from "./EditCollection";
import DateFilter from "./filters/DateFilter";
import FieldColumnFilter from "./filters/FieldColumnFilter";
import NumberFilter from "./filters/NumberFilter";
import SearchFilter from "./filters/SearchFilter";
import UsagesFilter from "./filters/UsagesFilter";
import { useDisplay } from "./hooks/useDisplay";
import { useQueryParamsToState } from "./hooks/useQueryParamsToState";
import { useWindowSize } from "./hooks/useWindowSize";
import ReactDragListViewColumn from "./ReactDragListViewColumn";
import ResizableTitle from "./ResizableTitle";
import { Field } from "./services/collectionFields";
import { CollectionPayload, Item } from "./services/collectionItems";

import "./CollectionTable.css";

const dynamicFields: Record<string, Field[]> = {
  transactions_fao: [
    {
      name: "SITG",
      group: "",
      label: "SITG",
      type: "calculated",
      sort: 4,
      ui: "link",
      configuration: {
        filterable: false,
        sortable: false,
        width: 50,
      },
    },
  ],
};

const renderTableFieldColumn = (
  collectionId: string,
  field: Field,
  sort: Record<string, string | undefined>,
  filterState: [
    Record<string, string[]>,
    (filters: Record<string, string[]>) => void
  ],
  navigate: () => void,
  updateField: (fields: Field) => void
) => {
  const { displayNumber } = useDisplay();
  const {
    label,
    name,
    ui,
    type,
    options,
    configuration: { filterable, sortable, width },
  } = field;

  const [filters, setFilters] = filterState;

  const filterProps = filterable
    ? {
        filteredValue: filters[name] || null,
        filtered: !!filters[name],
        filterDropdown: (props: FilterDropdownProps) => {
          return (
            <SearchFilter
              field={name}
              collectionId={collectionId}
              dropdownProps={props}
            />
          );
        },
      }
    : {};

  const headerCellProps = {
    onHeaderCell: () => ({
      width,
      onResize: (event: any) => {
        if (event.movementX) {
          const width = field.configuration.width + event.movementX;
          updateField({
            ...field,
            configuration: {
              ...field.configuration,
              width: width < 40 ? 40 : width,
            },
          });
        }
      },
    }),
  };

  let column = (
    <Table.Column
      key={name}
      title={<span className="dragHandler">{label}</span>}
      dataIndex={name}
      width={width}
      ellipsis={true}
      sorter={sortable ? { multiple: 1 } : false}
      sortOrder={sort[name] as SortOrder}
      {...filterProps}
      {...headerCellProps}
    />
  );

  if (ui === "select-dropdown") {
    column = (
      <Table.Column
        key={name}
        title={<span className="dragHandler">{label}</span>}
        dataIndex={name}
        width={width}
        ellipsis={true}
        render={(value) => {
          const option = options?.find((option) => option.value === value);
          return value ? (
            <Tag color={option?.color || "#616161"} key={value}>
              {option?.label}
            </Tag>
          ) : (
            <div />
          );
        }}
        sorter={sortable ? { multiple: 1 } : false}
        sortOrder={sort[name] as SortOrder}
        {...filterProps}
        {...headerCellProps}
      />
    );
  }

  if (ui === "boolean") {
    column = (
      <Table.Column
        key={name}
        title={<span className="dragHandler">{label}</span>}
        dataIndex={name}
        width={width}
        ellipsis={true}
        render={(value) => {
          return <Checkbox disabled checked={!!value} />;
        }}
        sorter={sortable ? { multiple: 1 } : false}
        sortOrder={sort[name] as SortOrder}
      />
    );
  }

  if (ui === "datetime") {
    const filterProps = filterable
      ? {
          filteredValue: filters[name] || null,
          filtered: !!filters[name],
          filterDropdown: (props: FilterDropdownProps) => {
            return <DateFilter dropdownProps={props} />;
          },
        }
      : {};
    column = (
      <Table.Column
        key={name}
        title={<span className="dragHandler">{label}</span>}
        dataIndex={name}
        width={width}
        ellipsis={true}
        render={(value) => {
          try {
            return value
              ? new Intl.DateTimeFormat("fr").format(new Date(value))
              : "";
          } catch (error) {
            console.error(error);
          }
        }}
        sorter={sortable ? { multiple: 1 } : false}
        sortOrder={sort[name] as SortOrder}
        {...filterProps}
        {...headerCellProps}
      />
    );
  }

  if (ui === "map") {
    column = (
      <Table.Column
        key={name}
        title={<span className="dragHandler">{label}</span>}
        dataIndex={name}
        width={width}
        ellipsis={true}
        render={(value) => {
          return (
            value &&
            value.coordinates && (
              <Link
                target="_blank"
                to={`https://www.google.ch/maps/place/${value.coordinates[1]},${value.coordinates[0]}`}
              >
                <Button
                  size="small"
                  type="link"
                  icon={<EnvironmentOutlined />}
                ></Button>
              </Link>
            )
          );
        }}
        {...headerCellProps}
      />
    );
  }

  if (ui === "link") {
    column = (
      <Table.Column
        key={name}
        title={<span className="dragHandler">{label}</span>}
        width={width}
        ellipsis={true}
        render={({ NoCommune, NoParcelle }) => {
          return (
            <Link
              target="_blank"
              to={`https://map.sitg.ge.ch/app/?locparcelle=${NoCommune}:${NoParcelle}&mapresources=AMENAGEMENT`}
            >
              <Button
                size="small"
                type="link"
                icon={<EnvironmentOutlined />}
              ></Button>
            </Link>
          );
        }}
        {...headerCellProps}
      />
    );
  }

  if (type === "integer" || type === "float") {
    const filterProps = filterable
      ? {
          filteredValue: filters[name] || null,
          filtered: !!filters[name],
          filterDropdown: (props: FilterDropdownProps) => {
            return (
              <NumberFilter
                field={name}
                collectionId={collectionId}
                dropdownProps={props}
              />
            );
          },
        }
      : {};

    column = (
      <Table.Column
        className="number-column"
        key={name}
        title={<span className="dragHandler">{label}</span>}
        dataIndex={name}
        width={width}
        ellipsis={true}
        render={(value) =>
          !!value && displayNumber(`${value.toFixed(type === "float" ? 2 : 0)}`)
        }
        sorter={sortable ? { multiple: 1 } : false}
        sortOrder={sort[name] as SortOrder}
        {...filterProps}
        {...headerCellProps}
      />
    );
  }

  if (name === "Usages") {
    const filterProps = filterable
      ? {
          filteredValue: filters[name] || null,
          filtered: !!filters[name],
          filterDropdown: (props: FilterDropdownProps) => {
            return <UsagesFilter dropdownProps={props} />;
          },
        }
      : {};

    column = (
      <Table.Column
        key={name}
        title={<span className="dragHandler">{label}</span>}
        dataIndex={name}
        width={width}
        ellipsis={true}
        sorter={sortable ? { multiple: 1 } : false}
        sortOrder={sort[name] as SortOrder}
        {...filterProps}
        {...headerCellProps}
      />
    );
  }

  return (
    <Table.ColumnGroup
      title={
        filterable && (
          <FieldColumnFilter
            type={type}
            name={name}
            value={filters[name]}
            onFiltered={(filter) => {
              if (!filter) {
                delete filters[name];
                setFilters({ ...filters });
              } else {
                setFilters({ ...filters, [name]: [`${filter}***`] });
              }
              navigate();
            }}
          />
        )
      }
    >
      {column}
    </Table.ColumnGroup>
  );
};

const renderTableColumns = (
  collectionId: string,
  fields: Field[],
  sort: Record<string, string | undefined>,
  filters: [
    Record<string, string[]>,
    (filters: Record<string, string[]>) => void
  ],
  navigate: () => void,
  updateField: (field: Field) => void
) => {
  return fields.map((field: Field) =>
    renderTableFieldColumn(
      collectionId,
      field,
      sort,
      filters,
      navigate,
      updateField
    )
  );
};

const CollectionTable: FC = () => {
  const revalidator = useRevalidator();
  const { width, height } = useWindowSize();
  const navigate = useNavigate();
  const [editedItemIndex, setEditedItemIndex] = useState<number>(-1);
  const {
    filters,
    sort,
    setFilters,
    setPagination,
    setSort,
    toQueryParamsUrl,
  } = useQueryParamsToState();
  const { data, fields, limit, page, collectionId, admin } =
    useLoaderData() as CollectionPayload;
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [localFields, setLocalFields] = useState<Field[]>([]);

  useEffect(() => {
    const existingConfiguration = localStorage.getItem("x-fields");
    if (existingConfiguration) {
      setLocalFields(JSON.parse(existingConfiguration));
    } else {
      const extraFields = dynamicFields[collectionId];
      if (extraFields) {
        setLocalFields([...fields, ...extraFields]);
      } else {
        setLocalFields(fields);
      }
    }
  }, [fields]);

  useEffect(() => {
    localFields &&
      localFields.length > 0 &&
      localStorage.setItem("x-fields", JSON.stringify(localFields));
  }, [localFields]);

  useEffect(() => setSelectedRowKeys([]), [collectionId]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Item> | SorterResult<Item>[]
  ) => {
    const newFilters = Object.keys(filters).reduce((acc, key) => {
      if (!filters[key]) return acc;

      return {
        ...acc,
        [`${key.split("-")[0]}`]: filters[key],
      };
    }, {});
    setFilters(newFilters);

    const sorts = isArray(sorter) ? sorter : ([sorter] as SorterResult<Item>[]);

    setSort(
      sorts.reduce(
        (acc: Record<string, string | undefined>, { field, order }) => ({
          ...acc,
          [`${field}`]: !order ? undefined : order.toString(),
        }),
        {}
      )
    );

    setPagination(pagination.current, pagination.pageSize);

    navigate(`?${toQueryParamsUrl()}`);
  };

  const handleCloseEdit = () => {
    revalidator.revalidate();
    setEditedItemIndex(-1);
  };

  return (
    <Suspense
      fallback={
        <div
          style={{
            width: "100%",
            height: "calc(100% - 64px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            flexDirection: "column",
            margin: "48px 0",
          }}
        >
          <Spin indicator={<LoadingOutlined style={{ fontSize: 56 }} spin />} />
          <Typography.Link style={{ marginTop: 24, fontSize: 16 }}>
            Chargement en cours...
          </Typography.Link>
        </div>
      }
    >
      <Await resolve={data}>
        {(data) => {
          return (
            <>
              <ReactDragListViewColumn
                onFieldMove={(from: number, to: number) => {
                  const newLocalFields = JSON.parse(
                    JSON.stringify(localFields)
                  );
                  const field = newLocalFields.splice(from, 1)[0];
                  newLocalFields.splice(to, 0, field);
                  setLocalFields(newLocalFields);
                }}
              >
                <Table
                  bordered
                  style={{
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}
                  size="small"
                  dataSource={data.items}
                  pagination={{
                    pageSize: limit,
                    total: data.nbItems,
                    current: page,
                    showTotal: (total) => (
                      <div>
                        {total} / {data.total} éléments
                      </div>
                    ),
                  }}
                  scroll={{ x: width, y: height - 250 }}
                  onRow={(record, index) => {
                    return {
                      onClick: () => {
                        index !== undefined && setEditedItemIndex(index);
                      },
                    };
                  }}
                  rowSelection={{
                    selectedRowKeys,
                    onChange: (selectedRowKeys) => {
                      setSelectedRowKeys(selectedRowKeys);
                    },
                  }}
                  onChange={handleTableChange}
                  title={() => (
                    <CollectionTableHeader
                      collectionId={collectionId}
                      selectedRowKeys={selectedRowKeys}
                      admin={admin}
                    />
                  )}
                  components={{ header: { cell: ResizableTitle } }}
                >
                  {renderTableColumns(
                    collectionId,
                    localFields,
                    sort,
                    [filters, setFilters],
                    () => {
                      setPagination(1, limit);
                      navigate(`?${toQueryParamsUrl()}`);
                    },
                    (field: Field) => {
                      const fieldIndex = localFields.findIndex(
                        (f) => f.name === field.name
                      );
                      const newLocalFields = JSON.parse(
                        JSON.stringify(localFields)
                      );
                      newLocalFields[fieldIndex] = field;
                      setLocalFields(newLocalFields);
                    }
                  )}
                </Table>
              </ReactDragListViewColumn>

              <EditCollection
                editItem={data.items[editedItemIndex]}
                onCloseEdit={handleCloseEdit}
                onEditNext={
                  editedItemIndex < limit - 1
                    ? () => setEditedItemIndex(editedItemIndex + 1)
                    : undefined
                }
                onEditPrevious={
                  editedItemIndex > 0
                    ? () => setEditedItemIndex(editedItemIndex - 1)
                    : undefined
                }
              />
            </>
          );
        }}
      </Await>
    </Suspense>
  );
};

export default CollectionTable;
