import { pick, startCase } from "lodash";

import axiosInstance from "../axiosInstance";

type UI =
  | "numeric"
  | "select-dropdown"
  | "datetime"
  | "input"
  | "input-multiline"
  | "map"
  | "link"
  | "boolean";

export const columnWidthByUiType: Record<UI, number> = {
  datetime: 180,
  "input-multiline": 300,
  input: 150,
  numeric: 80,
  "select-dropdown": 120,
  map: 80,
  link: 60,
  boolean: 80,
};

export type FieldType =
  | "integer"
  | "string"
  | "date"
  | "text"
  | "geometry.Point"
  | "calculated"
  | "boolean"
  | "float";

export interface FieldOption {
  label: string;
  value: string;
  color: string;
}

export interface FieldConfiguration {
  filterable: boolean;
  sortable: boolean;
  width: number;
  highlighted?: boolean;
}

export interface Field {
  name: string;
  type: FieldType;
  ui: UI;
  label: string;
  group: string;
  options?: FieldOption[];
  configuration: FieldConfiguration;
  sort: number;
}

export const statusColors: Record<string, string> = {
  error: "red",
  draft: "orange",
  archived: "lightgrey",
  published: "green",
  test: "yellow",
};

const getConfiguration = (field: any): FieldConfiguration => {
  if (
    field.meta.translations?.[0] &&
    field.meta.translations?.[0]?.language === "af-ZA" &&
    field.meta.translations?.[0]?.translation
  ) {
    const configuration = JSON.parse(field.meta.translations?.[0]?.translation);

    return {
      ...configuration,
      width:
        configuration.width || columnWidthByUiType[field.meta.interface as UI],
    };
  }

  return {
    filterable: true,
    sortable: true,
    width: columnWidthByUiType[field.meta.interface as UI],
  };
};

export const getCollectionFields = async (
  collectionsId: string
): Promise<Field[]> => {
  const response = await axiosInstance.get(
    `https://melcarnedirectus2.digipulse.ch/fields/${collectionsId}`
  );
  const fieldsWithSchema = response.data.data.filter(
    (field: any) =>
      !!field.schema &&
      field.schema.schema === "public" &&
      field.meta.hidden === false
  );

  const fieldsSpecification = fieldsWithSchema.map((field: any) => {
    return {
      name: field.field,
      type: field.type,
      ui: field.meta.interface,
      // to be updated with the translations
      label:
        field.meta.translations?.[0]?.language === "fr-FR" &&
        field.meta.translations?.[0]?.translation
          ? field.meta.translations?.[0]?.translation
          : startCase(field.meta.field.replaceAll("_", " ")),
      group: field.meta.group === null ? "" : field.meta.group,
      options: (field.meta?.options?.choices || []).map((choice: any) => ({
        value: choice.value,
        label: choice.text,
        color: choice.color,
      })),
      configuration: getConfiguration(field),
      sort: field.meta.sort,
    };
  });

  return fieldsSpecification;
};

export type GroupedFields = Record<string, Field[]>;

const inputFields = [
  "SurfaceBrutHab",
  "Balcon",
  "Terrasse",
  "emprise_au_sol",
  "Niveaux_HS",
  "Annexe_Prix",
  "Prix",
  "SousType",
  "status",
  "Terrain",
];

export const calculatedFields: Record<
  string,
  (field: string, editedItem: any) => Promise<any>
> = {
  transactions_fao: async (field: string, editedItem: any) => {
    if (inputFields.includes(field)) {
      const response = await axiosInstance.post(
        "https://melcarnebackend.digipulse.ch/calculate_svb",
        pick(editedItem, inputFields)
      );

      if (response.data["error"]) {
        console.error("Error during calculation", response.data["error"]);
      }

      return response.data;
    }

    return {};
  },
};
