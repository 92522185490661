import { useState } from "react";

import { Button, Form, Input, message } from "antd";
import { useNavigate } from "react-router-dom";

import axiosInstance from "./axiosInstance";

const Login = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);

  const handleLogin = async (values: any) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(
        "https://melcarnedirectus2.digipulse.ch/auth/login",
        values
      );
      localStorage.setItem("x-token", JSON.stringify(response.data.data));
      navigate("/");
    } catch (error) {
      console.error(error);
      messageApi.open({
        type: "error",
        content:
          "Vos informations d'identification sont incorrectes. Veuillez réessayer!",
        duration: 5,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {contextHolder}
      <img height={96} src="/logo.svg" alt="Mercarne" />
      <Form
        name="normal_login"
        style={{ width: 300 }}
        initialValues={{ remember: true }}
        onFinish={handleLogin}
        layout="vertical"
      >
        <Form.Item
          name="email"
          rules={[
            {
              type: "email",
              message: "L'email est invalid!",
            },
            { required: true, message: "L'email est obligatoire!" },
          ]}
        >
          <Input placeholder="E-mail" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: "Le mot de passe est obligatoire!" },
          ]}
        >
          <Input type="password" placeholder="Mot de passe" />
        </Form.Item>
        <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button loading={loading} type="primary" htmlType="submit">
            {"Se connecter"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
