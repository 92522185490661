export const useDisplay = () => {
  return {
    displayNumber: (value: any) =>
      value ? `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}` : "-",
    displayPrice: (value: any) =>
      value
        ? `CHF ${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`
        : "-",
  };
};
